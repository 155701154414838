import React, { useContext } from "react";
import idiomData from "../../components/IdiomSelector/IdiomSelector";
import { LanguageContext } from "../../components/Language/Language";
import Mailer from "../../components/Mailer/Mailer";
import insta from "./assets/images/insta.svg";
import lkdin from "./assets/images/lkdin.svg";
import "./mobile.css";
import "./styles.css";

function Contact() {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = "Contact-" + language;
  const text = idiomData[selectedLanguage];

  window.addEventListener("scroll", function () {
    const title = document.querySelectorAll(".title");
    const content = document.querySelectorAll(".content");
    const screenHeight = window.innerHeight;

    title.forEach(function (text) {
      const textPosition = text.getBoundingClientRect().top;

      if (textPosition < screenHeight) {
        text.classList.add("appear");
      }
    });
    content.forEach(function (content) {
      const textPosition = content.getBoundingClientRect().top;

      if (textPosition < screenHeight) {
        content.classList.add("appear");
      }
    });
  });

  return (
    <>
      <div className="contact-block">
        <div className="contact-text">
          <h1 className="contact-title title">{text.Title}</h1>
          <p className="contact-content content">{text.Content}</p>
          <ul className="contact-list-block ">
            {Object.keys(text.List).map((key) => (
              <>
                <h2 className="contact-list-title content">
                  {text.List[key].Title}
                </h2>
                <pre
                  dangerouslySetInnerHTML={{
                    __html: text.List[key].Content,
                  }}
                  className="contact-list-content content"
                  style={{ whiteSpace: "break-spaces" }}
                ></pre>
              </>
            ))}
          </ul>
        </div>
        <div className="contact-form  title">
          <Mailer />
        </div>
      </div>
      <div className="social-media">
        <a
          href="https://linkedin.com/company/agenciaweonne/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={lkdin} alt="linkedin" />
        </a>

        <a
          href="https://instagram.com/agenciaweonne/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={insta} alt="instagram" />
        </a>
      </div>
    </>
  );
}

export default Contact;
