import React, { useContext } from "react";
import footerLogo from "../../assets/images/footer-logo.png";
import idiomData from "../IdiomSelector/IdiomSelector";
import { LanguageContext } from "../Language/Language";
import "./styles.css";

import insta from "./assets/images/insta.svg";
import lkdin from "./assets/images/lkdin.svg";

function Footer() {
  const { language } = useContext(LanguageContext);
  const selectedLanguage = "Footer-" + language;
  const text = idiomData[selectedLanguage];

  window.addEventListener("scroll", function () {
    const title = document.querySelectorAll(".title");
    const content = document.querySelectorAll(".content");
    const screenHeight = window.innerHeight;

    title.forEach(function (text) {
      const textPosition = text.getBoundingClientRect().top;

      if (textPosition < screenHeight) {
        text.classList.add("appear");
      }
    });
    content.forEach(function (content) {
      const textPosition = content.getBoundingClientRect().top;

      if (textPosition < screenHeight) {
        content.classList.add("appear");
      }
    });
  });

  return (
    <div className="footer-container">
      <div className="footer-logo-container">
        {/*<LanguageSelector className="footer-language" /> */}
        <img src={footerLogo} className="footer-logo" />
      </div>
      <div className="footer-blocks-container">
        <div className="footer-blocks">
          {Object.keys(text.List).map((key) => (
            <div className="footer-block">
              <h2 className="footer-block-title">{text.List[key].Title}</h2>
              <pre
                className="footer-block-content"
                dangerouslySetInnerHTML={{
                  __html: text.List[key].Content,
                }}
              ></pre>
            </div>
          ))}
        </div>
        <div className="footer-social">
          <h2 className="footer-block-title">{text.social.Title}</h2>
          <div className="footer-social-itens">
            <a
              href="https://linkedin.com/company/agenciaweonne/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={lkdin} />
            </a>

            <a
              href="https://instagram.com/agenciaweonne/"
              target="_blank"
              rel="noreferrer"
            >
              <img src={insta} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
